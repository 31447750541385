import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)
import bootstrap from 'modules/bootstrap'
const bootstrapRoute = bootstrap.getRoutes()
const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/layout/unauth/unauth.vue'),
    children: [
      ...bootstrapRoute.public,
      {
        path: '404',
        name: '404',
        component: () => import('modules/base/views/public404.vue'),
      },
    ],
  },
  {
    path: '/cms',
    component: () => import('@/layout/admin/admin.vue'),
    children: [
      ...bootstrapRoute.admin,
      {
        path: '404',
        name: 'cms.404',
        component: () => import('modules/base/views/cms404.vue'),
      },
    ],
  },
  {
    path: '/cms/*',
    redirect: '/cms/404',
  },
  {
    path: '/*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(window.rootVm && window.vueRouteChangePrevent) {
    if(window.vueRouteChangePrevent.hasPrevent()) {
      const confirmResult = confirm(window.rootVm.$t('exit.confirm'))
      if(!confirmResult) return
    }
    window.vueRouteChangePrevent.reset()
  }
  next()
})

export default router
